import $ from 'jquery';
import { showPopBox, hidePopBox } from './main-scripts/pop-blocks.js';

var $script = require("scriptjs");

import './main-scripts/alfa-form';
import './main-scripts/sk_scrollTo';

var imagesLoaded = require('imagesloaded');

import ionRangeSlider from "ion-rangeslider";
import smoothScrollbar from 'smooth-scrollbar';
import { windowWidth, windowHeight, scrollbar, scrollerInit, globalScrollingAnimations, updateEffectsController, updateEffectsControllerID, addEffectToController } from './main-scripts/globals';
import { gsap, Linear, Power1, TimelineLite } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Swiper, { Navigation, Pagination, Autoplay, EffectFade, Parallax, Scrollbar, Controller } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Parallax, Scrollbar, Controller]);
import Inputmask from "inputmask";

Number.isNaN = Number.isNaN || function (value) {
  return typeof value === 'number' && isNaN(value);
};

var terminationEvent = 'onpagehide' in self ? 'pagehide' : 'unload';
var $document = $(document);
export function scrollerUpdate(scroller) {
  var scroll = smoothScrollbar.get(scroller[0]);
  scroll.update();
  var scrollSizes = scroll.getSize();

  if (scrollSizes.container.height < scrollSizes.content.height) {
    scroller.addClass('hasScroll');
  } else {
    scroller.removeClass('hasScroll');
  }
} // styling for custom scroll  http://manos.malihu.gr/jquery-custom-content-scroller/

$('.selectric-scroll').each(function () {
  var fakeScroll = $(this);
  var scrollbarSlider = smoothScrollbar.init(fakeScroll[0], {
    damping: 0.07,
    continuousScrolling: false
  });
});
$('._gCustomScroll').each(function () {
  var scrollElement = $(this);
  var itemScrollbar;
  var isContinious = false;

  if ($(this).data('continium')) {
    isContinious = true;
  }

  itemScrollbar = smoothScrollbar.init(scrollElement[0], {
    continuousScrolling: isContinious
  });
  setTimeout(function () {
    scrollerUpdate(scrollElement);
  }, 300);
  $(window).on('resize', function () {
    scrollerUpdate(scrollElement);
  });
});
$('.no-device_xs ._gCustomScrollNOXS').each(function () {
  var scrollElement = $(this);
  var itemScrollbar;
  var isContinious = false;

  if ($(this).data('continium')) {
    isContinious = true;
  }

  itemScrollbar = smoothScrollbar.init(scrollElement[0], {
    continuousScrolling: isContinious
  });
  setTimeout(function () {
    scrollerUpdate(scrollElement);
  }, 300);
});
$('.jsIndexTopSwiper').each(function () {
  var carouselWrapper = $(this);
  var carouselDiv = $(this).find(".swiper-container");
  var mySwiper = new Swiper(carouselDiv[0], {
    slidesPerView: 1,
    centeredSlides: false,
    parallax: true,
    loop: true,
    watchSlidesVisibility: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    scrollbar: {
      el: carouselWrapper.find(".swiper-scrollbar")[0],
      draggable: false
    },
    navigation: {
      nextEl: carouselWrapper.find(".swiper-button-next")[0],
      prevEl: carouselWrapper.find(".swiper-button-prev")[0]
    }
  });

  function completeSliderTL() {
    $(".jsIndexTopSwiper").removeClass("with-effect");
  }

  mySwiper.on('sliderFirstMove', function () {
    completeSliderTL();
  });
  mySwiper.on('slideChangeTransitionStart', function () {
    completeSliderTL();
  });

  if (!Modernizr.device_xs) {
    var updateNumbers = function updateNumbers() {
      effectLength = window.innerHeight;
      effectLength2 = window.innerHeight + 40;
      wantToY = window.innerHeight / 2.5;
    };

    var $element = $(this);
    var effectLength;
    var effectLength2;
    var wantToY;
    updateNumbers();
    var thisTL = gsap.timeline({
      scrollTrigger: {
        start: 0,
        end: function end() {
          return "+=" + effectLength;
        },
        scrub: true
      }
    });
    thisTL.fromTo($element.find('.swiper-wrapper, .arrows-box'), {
      y: 0,
      opacity: 1
    }, {
      y: function y() {
        return wantToY;
      },
      opacity: 0.5,
      ease: Linear.easeNone
    }, 0);
    var $firstSlide = $(".jsIndexTopSwiper .swiper-slide:first-child");
    var indexTopSliderTL = gsap.timeline({
      paused: true,
      onComplete: completeSliderTL
    });
    indexTopSliderTL.to(".jsIndexTopSwiper .swiper-slide-active .slide-bg img", 2, {
      opacity: 1,
      scale: 1
    }, 0);
    indexTopSliderTL.to(".jsIndexTopSwiper .swiper-slide-active .slide-textbox", 1.5, {
      opacity: 1,
      y: 0,
      ease: Power1.easeInOut
    }, 0);
    var imgLoad = imagesLoaded($firstSlide.find(".slide-bg"));
    imgLoad.on('done', function () {
      indexTopSliderTL.play();
    });
    var thisTLHead = gsap.timeline({
      scrollTrigger: {
        trigger: '.otrasli-block-wrapper',
        start: function start() {
          return "top -5%";
        },
        end: false,
        toggleActions: "play none none reverse",
        scrub: false,
        onToggle: function onToggle(self) {
          if (self.isActive === true && self.progress < 0.5) {
            $(".body-page").removeClass("is-index");
          }

          if (self.isActive === false && self.progress < 1) {
            $(".body-page").addClass("is-index");
          }
        }
      }
    });
    window.addEventListener("resize", function () {
      updateNumbers();
      thisTLHead.invalidate();
      thisTL.invalidate();
    });
  }
});
$('.jsHistorySlider').each(function () {
  var carouselWrapper = $(this);
  var carouselDiv = $(this).find(".jsTextSwiper");
  var carouselDivBG = $(this).find(".jsBGSwiper");
  var $slidesCount = $(this).find(".slider-count em");
  var mySwiperBG = new Swiper(carouselDivBG[0], {
    slidesPerView: 1,
    effect: 'fade',
    followFinger: false,
    longSwipes: false,
    shortSwipes: false,
    loop: false,
    wrapAround: true,
    simulateTouch: false,
    fadeEffect: {
      crossFade: true
    }
  });
  var control = mySwiperBG;
  var effect = 'fade';

  if (Modernizr.device_xs) {
    effect = 'slide';
    control = false;
  }

  var mySwiper = new Swiper(carouselDiv[0], {
    slidesPerView: 1,
    grabCursor: false,
    parallax: true,
    touchStartPreventDefault: false,
    wrapAround: true,
    loop: false,
    effect: effect,
    navigation: {
      nextEl: carouselWrapper.find(".swiper-button-next")[0],
      prevEl: carouselWrapper.find(".swiper-button-prev")[0]
    },
    fadeEffect: {
      crossFade: true
    },
    controller: {
      control: mySwiperBG
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10,
        scrollbar: {
          el: carouselWrapper.find(".swiper-scrollbar")[0]
        }
      },
      768: {
        slidesPerView: 1
      }
    }
  });
  mySwiper.on('slideChange', function () {
    console.log('Slider change text');
    $slidesCount.text(mySwiper.realIndex + 1);
  });
});
$('.jsClientsSwiper').each(function () {
  var carouselWrapper = $(this);
  var carouselDiv = $(this).find(".swiper-container");
  var mySwiper = new Swiper(carouselDiv[0], {
    slidesPerView: 'auto',
    wrapAround: true,
    grabCursor: true,
    scrollbar: {
      el: carouselWrapper.find(".swiper-scrollbar")[0],
      draggable: true
    }
  });
});
$('.jsNewsSwiper').each(function () {
  var carouselWrapper = $(this);
  var carouselDiv = $(this).find(".swiper-container");
  var mySwiper = new Swiper(carouselDiv[0], {
    slidesPerView: 2.7,
    wrapAround: true,
    spaceBetween: 35,
    grabCursor: true,
    navigation: {
      nextEl: carouselWrapper.find(".swiper-button-next")[0],
      prevEl: carouselWrapper.find(".swiper-button-prev")[0]
    },
    pagination: {
      el: carouselWrapper.find(".swiper-pagination")[0]
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 2.7,
        spaceBetween: 35
      },
      1800: {
        slidesPerView: 3,
        spaceBetween: 40
      }
    }
  });
});
$('.jsCatCarousel').each(function () {
  var carouselWrapper = $(this);
  var carouselDiv = $(this).find(".swiper-container");
  var mySwiper = new Swiper(carouselDiv[0], {
    slidesPerView: 'auto',
    wrapAround: true,
    navigation: {
      nextEl: carouselWrapper.find(".swiper-button-next")[0]
    },
    scrollbar: {
      el: carouselWrapper.find(".swiper-scrollbar")[0],
      draggable: true
    },
    breakpoints: {
      // when window width is >= 320px
      1800: {
        slidesPerView: 4.3,
        spaceBetween: 40
      }
    }
  });
});

if ($('.jsCatCarousel').length) {
  var catBody = $(".jsCatCarousel").find(".cat-carousel-wrap");
  var catBodyWrapper = $(".jsCatCarousel").closest(".catalog-section-wrapper");
  $(window).on('resize', function () {
    if (windowWidth >= 1800) {
      var catBodyWrapperWidth = catBodyWrapper.outerWidth();
      var calculatedWidth = (window.outerWidth - catBodyWrapperWidth) / 2 - 20;
      var prepairedWidth = catBodyWrapperWidth - $(".catalog-sidebar").outerWidth() + calculatedWidth;
      console.log(prepairedWidth);
      catBody.width(prepairedWidth);
      $(".jsCatCarousel").each(function () {
        var swiper = $(this).find(".swiper-container")[0].swiper;
        swiper.update();
      });
    } else {
      catBody.css("width", '');
    }
  });
  $(window).trigger('resize');
}

$(document).ready(function () {
  $(".jsFixedSide").each(function () {
    var scrollSpy = new bootstrap.ScrollSpy(document.body, {
      target: '#navbar-example2'
    });
    var $this = $(this);
    var $sidebar = $(this).find(".catalog-sidebar");
    var ScrollTop;

    function compareScroll() {
      var offsetTop = $this.offset().top;
      ScrollTop = parseInt($(window).scrollTop());

      if (ScrollTop >= offsetTop - 55) {
        $sidebar.addClass("fixed");
      } else {
        $sidebar.removeClass("fixed");
      }
    }

    compareScroll();
    $(window).scroll(function () {
      compareScroll();
    });
    $(window).on('resize', function () {
      compareScroll();
    });
  });
}); //mobile collapse (tabs like)

$(".jsXsCollapse .xs-collapse-btn ").on("click", function (e) {
  $(this).toggleClass("actived");
  var target = $($(this).attr("href"));
  target.slideToggle("fast").toggleClass("active show");
  e.preventDefault();
});

if (Modernizr.device_xs) {
  $("footer .main-nav-col ul").appendTo($("footer .contact-col"));
}

$("header .main-nav ul").each(function () {
  $(this).closest("li").addClass("with-submenu");
});
$(".jsMaskPhone").each(function () {
  var im = new Inputmask("+7 (999) 999-99-99");
  im.mask($(this));
});
$(".jsContactMap").each(function () {
  var thisMap = $(this);
  var isLoaded = false;
  var mapID = $(this).attr("id");
  var mapOffsetTop = $(this).offset().top - windowHeight;

  if (!isLoaded) {
    import('./main-scripts/myMap.js').then(function (module) {
      $script("//maps.googleapis.com/maps/api/js?key=AIzaSyDEeIUZg7LMGmyJcoVbvzQzSVbsEWbm9FE", function () {
        module.tizerMap(thisMap, false);
      });
    });
    isLoaded = true;
  }
});
var mobileMenuOpened = false;
$(".js_mobileBtn").on("click", function () {
  $("body").toggleClass("menu-opened");
  $(this).toggleClass("btn-actived");

  if (mobileMenuOpened === false) {
    mobileMenuOpened = true;
    $(".mobile-menu").fadeIn("fast");
  } else {
    $(".mobile-menu").fadeOut("fast");
    mobileMenuOpened = false;
  }
});

if (Modernizr.device_xs) {
  $(".mobile-menu .mob-nav").append($(".main-nav"));
  $(".main-nav .with-submenu > a").each(function () {
    var currentLink = $(this).clone();
    currentLink.addClass("catLink").find("span").text("Перейти в категорию");
    $(this).closest("li").find("ul").prepend(currentLink);
  });
  $(".main-nav .with-submenu > a:not(.catLink)").click(function (e) {
    e.preventDefault();
    $(this).closest("li").siblings().removeClass('opened').find("ul").slideUp("fast");
    $(this).closest("li").toggleClass("opened").find("ul").slideToggle("fast");
  });
} //search filter change url when change form items


var tempDir = wp_vars.template_directory;
var blogUrl = wp_vars.blog_url;
$(".sidebar-filter-form").each(function () {
  var $form = $(this);
  var url = blogUrl + '/catalog/';
  var args = {};
  var $rangeSliderInput = $('.js-range-slider');
  $rangeSliderInput.ionRangeSlider({
    type: "double",
    min: 0,
    max: 12,
    from: 0,
    to: 12,
    drag_interval: true,
    grid: true,
    onChange: function onChange(data) {
      var from = data.from;
      var to = data.to;
      console.log(to);
      clearTimeout(this.changeTimer);
      this.changeTimer = setTimeout(function () {
        $rangeSliderInput.val(data.from + ',' + data.to);
      }, 1000);
    }
  });
  $form.on('change', 'input[type="checkbox"]', function () {
    collectFormValues();
  });

  function collectFormValues() {
    // loop over filters
    $form.find('fieldset').each(function () {
      // vars
      var filter = $(this).data('filter'),
          vals = []; // find checked inputs

      $(this).find('input:checked').each(function () {
        vals.push($(this).val());
      }); // append to args

      args[filter] = vals.join(',');
    });
  }
});