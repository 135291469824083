import $ from 'jquery';

function onEmptyCheckerInit(checkElement) {
  $('.form-control.js_inputEmptyChecker').on('keyup', function () {
    if ($(this).val()) {
      $(this).closest(".input-wrap").addClass('input_not_empty');
    } else {
      $(this).closest(".input-wrap").removeClass('input_not_empty');
    }
  }); //класс фокус при фокусе инпута

  $(".form-control.js_inputEmptyChecker").on('focus', function () {
    $(this).closest(".input-wrap").addClass('input_focused');
  }).blur(function () {
    $(this).closest(".input-wrap").removeClass('input_focused');
  });

  if (checkElement) {
    if (checkElement.val()) {
      checkElement.closest(".input-wrap").addClass('input_not_empty');
    } else {
      checkElement.closest(".input-wrap").removeClass('input_not_empty');
    }

    if (checkElement.attr("disabled")) {
      checkElement.closest(".input-wrap").addClass('input-disabled');
    } else {
      checkElement.closest(".input-wrap").removeClass('input-disabled');
    }
  }
}

onEmptyCheckerInit();