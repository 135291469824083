function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { TimelineMax, TweenLite, TweenMax, Linear } from 'gsap';
import $ from 'jquery';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export var windowWidth = window.innerWidth;
export var windowHeight = window.innerHeight;
window.effectsController = {
  items: []
};
window.isWorkEffectsReady = false;
import smoothScrollbar from "smooth-scrollbar";
window.worksEffectsArray = {
  items: []
};
var isResized = false;
$(window).on('resize', function () {
  windowHeight = window.innerHeight;
  windowWidth = window.innerWidth; //console.log("resized winHeight:" +windowHeight)
  // if(!Modernizr.device_xs){
  //   isResized = true;
  //   setTimeout(function () {
  //     allScrollingEffectsInit();
  //
  //   },500)
  // }
});
var scroller;
export var scrollbar;
export function scrollerInit() {
  scroller = document.getElementById('scrolled');

  if (!Modernizr.device_xs && scroller) {
    smoothScrollbar.destroy(document.getElementById('horiz-scrollbar'));
    var overscrollOptions = {
      enable: true,
      effect: navigator.userAgent.match(/Android/) ? 'glow' : 'bounce',
      damping: 0.2,
      maxOverscroll: 150,
      glowColor: '#222a2d'
    };
    scrollbar = smoothScrollbar.init(scroller, {
      damping: 0.07
    });
    ScrollTrigger.scrollerProxy(scroller, {
      scrollTop: function scrollTop(value) {
        if (arguments.length) {
          scrollbar.scrollTop = value; // setter
        }

        return scrollbar.scrollTop; // getter
      },
      getBoundingClientRect: function getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: windowWidth,
          height: windowHeight
        };
      }
    });
    scrollbar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({
      scroller: scroller
    });
  }
}
export function globalScrollingAnimations() {
  //global animations
  $("*[data-skarallax]").each(function (index, el) {
    var $element = $(this);
    var wantedToScroll = parseInt($element.data("skarallax"));
    var effectLength;

    var thisUpdate = function updateValues() {
      effectLength = wantedToScroll + $element.outerHeight() + windowHeight;
    };

    thisUpdate();
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        end: function end() {
          return "+=" + effectLength;
        },
        scrub: true
      }
    });
    thisTL.fromTo($element, {
      y: 0
    }, {
      y: function y() {
        return wantedToScroll;
      },
      ease: Linear.easeNone
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: thisUpdate,
      id: "data-skarallax" + index
    });
  }); //global animations

  $("*[data-skarallax-x]").each(function (index, el) {
    var $element = $(this);
    var wantedToScroll = parseInt($element.data("skarallax-x"));
    var effectLength;
    var additionLength = 0;

    if ($element.data("skarallax")) {
      additionLength = parseInt($element.data("skarallax"));
    }

    var thisUpdate = function updateValues() {
      effectLength = $element.outerHeight() + windowHeight + additionLength;
    };

    thisUpdate();
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        end: function end() {
          return "+=" + effectLength;
        },
        scrub: true
      }
    });
    thisTL.fromTo($element, {
      x: 0
    }, {
      x: function x() {
        return wantedToScroll;
      },
      ease: Linear.easeNone
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: thisUpdate,
      id: "data-skarallax-x" + index
    });
  }); //global animations

  $("*[data-skarallax-percent]").each(function (index, el) {
    var $element = $(this);
    var to = parseInt($element.data("skarallax-percent"));
    var from = 0;

    if ($element.data("from")) {
      from = parseInt($element.data("from"));
    }

    var effectLength;
    var blockHeight;
    var pixelsPercentFrom = 0;
    var pixelsPercentTo = 0;

    var thisUpdate = function updateValues() {
      blockHeight = $element.height();
      pixelsPercentFrom = blockHeight * from / 100;
      pixelsPercentTo = blockHeight * to / 100;
      effectLength = -pixelsPercentFrom + pixelsPercentTo + $element.outerHeight() + windowHeight;
    };

    thisUpdate();
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: function start() {
          return "top bottom -=" + pixelsPercentFrom;
        },
        end: function end() {
          return "+=" + effectLength;
        },
        scrub: true
      }
    });
    thisTL.fromTo($element, {
      yPercent: function yPercent() {
        return from;
      }
    }, {
      yPercent: function yPercent() {
        return to;
      },
      ease: Linear.easeNone
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: thisUpdate,
      id: "data-skarallax-percent" + index
    });
  }); //global animations

  $("*[data-skarallax-parallax]").each(function (index, el) {
    var $element = $(this);
    var from = 0;

    if ($element.data("from")) {
      from = parseInt($element.data("from"));
    }

    var effectLength;
    var blockHeight;
    var blockImageHeight;
    var wantedToScroll;
    var wantedFrom;

    var thisUpdate = function updateValues() {
      blockHeight = $element.height();
      blockImageHeight = $element.find('.image').outerHeight();
      wantedToScroll = blockImageHeight - blockHeight;
      wantedFrom = window.innerHeight / 2;
      effectLength = wantedToScroll + $element.outerHeight() + windowHeight;
    };

    thisUpdate();
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: function start() {
          return "top bottom";
        },
        end: function end() {
          return "+=" + effectLength;
        },
        scrub: true
      }
    });
    thisTL.fromTo($element.find('.image'), {
      y: function y() {
        return -wantedFrom;
      }
    }, {
      y: function y() {
        return wantedFrom;
      },
      ease: Linear.easeNone
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: thisUpdate,
      id: "data-skarallax-parallax" + index
    });
  });
  $("*[data-skarallax-parallax-top]").each(function (index, el) {
    var $element = $(this);
    var from = 0;
    var scaleFrom = 1;
    var scaleTo = 1;

    if ($element.data("from")) {
      from = parseInt($element.data("from"));
    }

    if ($element.data("scale-from")) {
      scaleFrom = parseFloat($element.data("scale-from"));
    }

    if ($element.data("scale-to")) {
      scaleTo = parseFloat($element.data("scale-to"));
    }

    var blockImageHeight;
    var wantedFrom;

    var thisUpdate = function updateValues() {
      blockImageHeight = $element.find('.image').outerHeight();
      wantedFrom = window.innerHeight / 2;
    };

    thisUpdate();
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: function start() {
          return "top bottom";
        },
        end: function end() {
          return "+=" + blockImageHeight;
        },
        scrub: true
      }
    });
    thisTL.fromTo($element.find('.image'), {
      y: function y() {
        return -wantedFrom;
      },
      scale: scaleFrom
    }, {
      y: function y() {
        return 0;
      },
      scale: scaleTo,
      ease: Linear.easeNone
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: thisUpdate,
      id: "data-skarallax-parallax" + index
    });
  });
  $("*[data-skarallax-scale]").each(function (index, el) {
    var $element = $(this);
    var wantedToScroll = 0;
    var additionDuration = 0;
    var scaleFrom = parseFloat($element.data("scale-from"));
    var scaleTo = parseFloat($element.data("skarallax-scale"));

    if ($element.data("skarallax-y")) {
      wantedToScroll += parseInt($element.data("skarallax-y"));
    }

    if ($element.data("diff")) {
      additionDuration += parseInt($element.data("diff"));
    }

    var effectLength;

    var thisUpdate = function updateValues() {
      effectLength = wantedToScroll + $element.outerHeight() + windowHeight + additionDuration;
    };

    thisUpdate();
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        end: function end() {
          return "+=" + effectLength;
        },
        scrub: true
      }
    });
    thisTL.fromTo($element, {
      y: 0,
      scale: scaleFrom
    }, {
      y: function y() {
        return wantedToScroll;
      },
      scale: scaleTo,
      ease: Linear.easeNone
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: thisUpdate,
      id: "data-skarallax-scale" + index
    });
  });
  $("*[data-fixed-end]").each(function (index, el) {
    var $element = $(this);
    var diff = 0;

    if ($element.data("diff")) {
      diff = parseInt($element.data("diff"));
    }

    var wantedToScroll, effectLength;

    var thisUpdate = function updateValues() {
      effectLength = windowHeight;
      wantedToScroll = windowHeight + diff;
    };

    thisUpdate();
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "bottom bottom",
        end: function end() {
          return "+=" + effectLength;
        },
        scrub: true
      }
    });
    thisTL.fromTo($element, {
      y: 0
    }, {
      y: function y() {
        return wantedToScroll;
      },
      ease: Linear.easeNone
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: thisUpdate,
      id: "data-fixed-end" + index
    });
  });
  $(".jsTitleAnime").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      y: 150,
      opacity: 0
    }, {
      y: function y() {
        return 0;
      },
      opacity: 1,
      duration: 1
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnime" + index
    });
  });
  $(".jsTitleAnime2").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      y: 150,
      opacity: 0
    }, {
      y: function y() {
        return 0;
      },
      opacity: 1,
      duration: 2
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnime" + index
    });
  });
  $(".jsTitleAnimeLeft").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      x: -50,
      opacity: 0
    }, {
      x: function x() {
        return 0;
      },
      opacity: 1,
      duration: 1
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnimeLeft" + index
    });
  });
  $(".jsTitleAnimeLeft2").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      x: -100,
      opacity: 0
    }, {
      x: function x() {
        return 0;
      },
      opacity: 1,
      duration: 1
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnimeLeft2" + index
    });
  });
  $(".jsTitleAnimeTop").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      y: -100,
      opacity: 0
    }, {
      y: function y() {
        return 0;
      },
      opacity: 1,
      duration: 1
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnimeTop" + index
    });
  });
  $(".jsTitleAnimeFade").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.7
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnimeFade" + index
    });
  });
  $(".jsTitleAnimeFade2").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top center",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 1
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnimeFade2" + index
    });
  });
  $(".jsTitleAnimeRight").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      x: 50,
      opacity: 0
    }, {
      x: function x() {
        return 0;
      },
      opacity: 1,
      duration: 1
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnimeRight" + index
    });
  });
  $(".jsTitleAnimeScale").each(function (index, el) {
    var $element = $(this);
    var thisTL = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        scrub: false,
        toggleActions: "play none none reverse"
      }
    });
    thisTL.fromTo($element, {
      scale: 2,
      opacity: 0
    }, {
      scale: function scale() {
        return 1;
      },
      opacity: 1,
      duration: 1
    }, 0);
    addEffectToController({
      timeLine: thisTL,
      updateFunc: null,
      id: "jsTitleAnimeScale" + index
    });
  });
  $(".jsAnimeWidth").each(function (index, el) {
    var $element = $(this);
    var thisTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: $element,
        start: "top bottom",
        toggleActions: "play none none reverse"
      }
    });
    thisTimeLine.fromTo($element, {
      width: 0
    }, {
      width: "100%",
      duration: 1
    }, 0);
    addEffectToController({
      timeLine: thisTimeLine,
      updateFunc: null,
      id: "jsAnimeWidth" + index
    });
  });
  $("body").on('click', '.js_scrollToLink', function (e) {
    var speed = 1500;
    var headerHeight = $("header").outerHeight();

    if ($(this).data("speed")) {
      speed = $(this).data("speed");
    }

    if (!Modernizr.device_xs && $("#scrolled").length) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        if ($('#scrolled').length) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          var scrolledAmount = parseInt(target.offset().top - headerHeight);
          console.log(scrollbar.offset.y);
          var scrolledDiff = scrolledAmount + parseInt(scrollbar.offset.y);

          if ($(this).hasClass("scroll-bottom-link")) {
            scrollbar.scrollTo(0, windowHeight, speed, false);
          } else {
            if (target.length) {
              scrollbar.scrollTo(0, scrolledDiff, speed, false);
            }
          }
        }

        return false;
      }
    } else {
      e.preventDefault();

      if ($(this).hasClass("scroll-bottom-link")) {
        gsap.to(window, {
          duration: speed / 1000,
          scrollTo: windowHeight
        });
      } else {
        gsap.to(window, {
          duration: speed / 1000,
          scrollTo: this.hash
        });
      }
    }
  });
}
export function scrollAfterLoadPage(anchor) {
  console.log(anchor);

  if (!Modernizr.device_xs && $("#scrolled").length) {
    var target = $(anchor);
    console.log(target);
    var scrolledAmount = parseInt(target.offset().top);

    if (target.length) {
      scrollbar.scrollTo(0, scrolledAmount, 1, false);
    }
  } else {
    gsap.to(window, {
      duration: 1000,
      scrollTo: anchor
    });
  }
}
export function updateEffectsController() {
  var _iterator = _createForOfIteratorHelper(effectsController.items),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;

      if (item.updateFunc) {
        item.updateFunc();
      }

      item.timeLine.invalidate();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}
export function updateEffectsControllerID(itemID) {
  var _iterator2 = _createForOfIteratorHelper(effectsController.items),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var item = _step2.value;

      if (item.id === itemID) {
        if (item.updateFunc) {
          item.updateFunc();
        } //console.log("Обновляем скролл контроллер итема: "+ itemID)


        item.timeLine.invalidate();
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
}
export function addEffectToController(itemToAdd) {
  effectsController.items.push({
    timeLine: itemToAdd.timeLine,
    updateFunc: itemToAdd.updateFunc,
    element: itemToAdd.element,
    id: itemToAdd.id
  });
}
window.addEventListener("resize", function () {
  updateEffectsController();
});

(function init100vh() {
  function setHeight() {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
  }

  setHeight();
  window.addEventListener('resize', setHeight);
})();